import TimelineMain from "views/TimelineMain.js";

const routesMain = [
    {
        path: "Timeline/:URL_Id",
        name: "Timeline",
        icon: "ni ni-bullet-list-67 text-grey",
        component: TimelineMain,
        layout: "/",
    }
];

export default routesMain;
