import React from "react";
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// core components
import MainNavbar from "../components/Navbars/MainNavbar.js";
import MainFooter from "../components/Footers/MainFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routesMain from "routesMain.js";

function Main () {
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const location = useLocation();
    const mainContentRef = React.useRef(null);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContentRef.current.scrollTop = 0;
    }, [location]);
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const getBrandText = (path) => {
        for (let i = 0; i < routesMain.length; i++) {
            if (location.pathname.indexOf(routesMain[i].layout + routesMain[i].path) !== -1) {
                return routesMain[i].name;
            }
        }
        return "Brand";
    };
    // toggles collapse between mini sidenav and normal
    const toggleSidenav = (e) => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        setSidenavOpen(!sidenavOpen);
    };
    const getNavbarTheme = () => {
        return location.pathname.indexOf("Main/alternative-dashboard") === -1
            ? "dark"
            : "light";
    };

    return (
        <>
            {/* <Sidebar
                routes={routesMain}
                toggleSidenav={toggleSidenav}
                sidenavOpen={sidenavOpen}
                logo={{
                    imgSrc: require("../assets/img/brand/TIAOYUEH.png").default,
                    imgAlt: "...",
                }}
            /> */}
            <div className="main-content" ref={mainContentRef}>
                <MainNavbar
                    theme={getNavbarTheme()}
                    // toggleSidenav={toggleSidenav}
                    // sidenavOpen={sidenavOpen}
                    brandText={getBrandText(location.pathname)}
                />
                <Switch>
                    {getRoutes(routesMain)}
                    <Redirect from="*" to="/Timeline" />
                </Switch>
                <MainFooter />
            </div>
            {sidenavOpen ? (
                <div className="backdrop d-xl-none" onClick={toggleSidenav} />
            ) : null}
        </>
    );
}

export default Main;
